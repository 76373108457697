import Provider from '@components/Provider';
import React from 'react';
import AppPresenter from './AppPresenter';
import FeedBackBtn from './FeedbackBtn';
import PreNoticePresenter from '@components/Dialog/PreNoticePresenter';

const AppContainer: React.FC = () => {
  return (
    <Provider>
      <PreNoticePresenter />
      <AppPresenter />
      <FeedBackBtn />
    </Provider>
  );
};

export default AppContainer;
