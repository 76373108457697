import React, { useState, useEffect } from 'react';
import ErrorNoticeImg from './assets/errorNotice.png';
import { colorSet } from '@components/Provider';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '64px 32px 32px 32px',
    textAlign: 'center',
    zIndex: 3000,
    [theme.breakpoints.down('xs')]: {
      padding: '48px 24px 26px 24px'
    }
  },
  banner: {
    margin: 'auto',
    marginBottom: 32,
    width: 315,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
      width: 215
    }
  },
  content: {
    padding: 0
  },
  footer: {
    marginTop: 24,
    backgroundColor: colorSet.gray200,
    borderRadius: 4,
    height: 80,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 46,
    '& b': {
      color: colorSet.primary500
    }
  }
}));

const PreNoticePresenter: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useState<'kr' | 'en'>('kr');

  useEffect(() => {
    const lastClosedDate = localStorage.getItem('lastModalCloseDate');
    const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD 형식

    if (lastClosedDate !== today) {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    const today = new Date().toISOString().split('T')[0];
    localStorage.setItem('lastModalCloseDate', today);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} classes={{ paper: classes.paper }}>
      <div
        style={{
          position: 'absolute',
          top: 28,
          right: 32,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 4
        }}
        onClick={() => setLang((prev) => (prev === 'kr' ? 'en' : 'kr'))}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.4969 9.21619 21.3896 6.5473 19.4212 4.57884C17.4527 2.61039 14.7838 1.50314 12 1.5ZM3 12C3.00205 10.9773 3.17963 9.96259 3.525 9H7.5L11.25 12L9.15 14.7697C9.04169 14.9141 8.98912 15.0928 9.00193 15.2729C9.01475 15.4529 9.09209 15.6224 9.21976 15.75L11.25 18V20.9619C9.00255 20.7722 6.90803 19.7463 5.38046 18.0869C3.85289 16.4275 3.00343 14.2554 3 12ZM16.0761 20.0135L17.9854 14.239C18.0127 14.1025 18.0015 13.961 17.9529 13.8305C17.9044 13.7 17.8204 13.5855 17.7105 13.5L11.25 8.25L12.75 6.75H16.7095C16.8669 6.75001 17.0203 6.7005 17.148 6.60849C17.2757 6.51647 17.3712 6.38661 17.421 6.2373L17.7926 5.1225C18.9303 6.07738 19.8135 7.29956 20.363 8.67957C20.9124 10.0596 21.111 11.5543 20.9409 13.0299C20.7708 14.5056 20.2374 15.9159 19.3884 17.1347C18.5394 18.3536 17.4013 19.3428 16.0761 20.0138V20.0135Z"
            fill="#343A40"
          />
        </svg>

        <CustomizedTypography
          variant={'body1'}
          fontWeight="500"
          color="textPrimary"
        >
          {lang === 'kr' ? '한국어' : 'English'}
        </CustomizedTypography>
      </div>
      <img className={classes.banner} src={ErrorNoticeImg} alt="notice" />
      <CustomizedTypography
        variant={lang === 'kr' ? 'h2' : 'h3'}
        fontWeight="bold"
        gutterBottoms={16}
      >
        {lang === 'kr'
          ? 'CAPA Connect 서비스 종료 안내'
          : 'CAPA Connect Discontinuation Notice'}
      </CustomizedTypography>
      <DialogContent classes={{ root: classes.content }}>
        <CustomizedTypography
          variant={matches ? 'body2' : 'body1'}
          fontWeight="500"
          color="textSecondary"
          gutterBottoms={matches ? 40 : 46}
        >
          {lang === 'kr' ? (
            <>
              CAPA Connect 서비스를 <b>2025.02.03</b>부로 종료합니다.
            </>
          ) : (
            <>
              We regret to inform you that CAPA Connect will be discontinued on{' '}
              <b>2025.02.03.</b> Please back up your files before this date.
            </>
          )}
          {!matches && <br />}
          {lang === 'kr'
            ? '해당 일자 전까지 꼭 데이터를 백업해 주시기 바랍니다.'
            : 'We deeply appreciate your support and apologize for any inconvenience this may cause. If you have any questions or concerns, please contact our support team.'}
          {!matches && <br />}
          {lang === 'kr'
            ? '그동안 서비스를 이용해 주셔서 진심으로 감사드리며, 서비스 종료로 인해 불편을 끼쳐 드려 죄송합니다. 궁금하신 사항이 있으시면 고객 지원팀으로 언제든지 문의해 주시기 바랍니다. 감사합니다.'
            : 'Thank you again for using CAPA Connect.'}
        </CustomizedTypography>
        <CustomizedTypography
          variant="body1"
          fontWeight="bold"
          gutterBottoms={16}
        >
          {lang === 'kr' ? '서비스 종료 일시' : 'Service Discontinuation Date'}
        </CustomizedTypography>
        <div className={classes.footer}>
          <CustomizedTypography
            variant={matches ? 'body1' : 'h4'}
            fontWeight="500"
          >
            {lang === 'kr' ? <b>2025년 02월 03일</b> : <b>February 3, 2025</b>}
          </CustomizedTypography>
        </div>
      </DialogContent>
      <CustomizedButton
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={handleClose}
      >
        확인
      </CustomizedButton>
    </Dialog>
  );
};

export default PreNoticePresenter;
